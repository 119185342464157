.button-fix {
  overflow: hidden;
  position: relative;
}

.button-doge {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 180px;
  height: 100%;
  background-image: url("../assets/regular-doge.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.button-doge-cooler {
  background-image: url("../assets/glasses-doge.png");
}

.button-doge-top {
  transition: 0.2s bottom;
  bottom: 150%;
  transform: translate(-50%, 50%) rotate(180deg);
}

.button-doge-bottom {
  transition: 0.2s top;
  top: 150%;
}

.button-doge.button-doge-top.button-doge-shown {
  bottom: 50%;
}

.button-doge.button-doge-bottom.button-doge-shown {
  top: 50%;
}
