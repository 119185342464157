@tailwind base;
@layer base {
  select {
    padding: 4px 23px 4px 12px !important;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>");
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: calc(100% - 10px) 12px;

    appearance: none;
  }
}

@tailwind components;

@tailwind utilities;
@layer utilities {
  .grid-rows-fr-auto {
    grid-template-rows: 1fr auto;
  }
  .grid-rows-auto-fr {
    grid-template-rows: auto 1fr;
  }
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background: rgb(66, 66, 66);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(104, 104, 104);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(123, 123, 123);
}

::-webkit-scrollbar-thumb:active {
  background: rgb(161, 161, 161, 0.8);
}

option {
  background: #232323;
}
.emoji-mart-anchor {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}
