.chq-atc {
  display: inline-block;
  position: relative;
  width: "100%";
}

.chq-atc--button {
  background: rgb(51, 51, 51);
  border: 0;
  border-radius: 5px;
  box-sizing: border-box;
  color: #eaeaea;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  outline: none;
  padding: 4px 10px;
}

.chq-atc--button:focus {
  background-color: rgb(89, 89, 89);
}

.chq-atc--button:hover {
  background-color: rgb(89, 89, 89);
}

.chq-atc--button-svg {
  width: 30;
  height: 30;
  display: inline;
  vertical-align: text-top;
}

.chq-atc--button-svg path {
  fill: #eaeaea;
}

.chq-atc--dropdown {
  background-color: rgb(51, 51, 51);
  border-radius: 5px;
  box-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 1);
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.chq-atc--dropdown a {
  color: #eaeaea;
  display: block;
  padding: 8px 15px;
  text-decoration: none;
}

.chq-atc--dropdown a:hover {
  background-color: rgb(89, 89, 89);
}
